.root {
  width: 100%;
  padding-top: 10px;
  height: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.ideaTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0px 20px 0px 20px;
}

.addIdea {
  margin-left: auto;
}

.ideaContainer {
  display: flex;
  flex-direction: column;
}

.ideas {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px;
}

.idea {
  margin-bottom: 20px;
}
