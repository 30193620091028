.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.textInput {
  width: 230px;
}

@media (max-width: 501px) {
  .textInput {
    width: 100%;
  }
}
