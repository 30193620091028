.root {
  height: auto;
}

.toolbarClassName {
  text-decoration: none;
}

.wrapperClassName {
  height: auto;
  border-radius: 15px;
}

.editorClassName {
  height: 200px;
  padding: 0 10px;
  border: 1px rgb(212, 212, 212) solid;
  resize: vertical;
  border-radius: 5px;
}
