.root {
  width: 100%;
  height: 100%;
  font-family: 'GitlabMono';
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.section {
}

.priority {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.periodicity {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.type {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.dates {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.date {
  background-color: rgb(140, 255, 138);
  border: 1px solid rgb(65, 200, 63);
  display: inline-block;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  font-family: 'GitlabMono';
}

.tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  padding: 3px 10px;
  border-radius: 10px;
  background-color: royalblue;
}
