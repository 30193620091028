.root {
  height: auto;
  width: 100%;
  border: 1px solid rgb(111, 111, 111);
  border-radius: 6px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  position: relative;
  padding-top: 5px;
  &:hover {
    border-color: #2dcd3b;
  }
}

.index {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cart {
  height: auto;
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.deleteIcon {
  color: red;
}

.updateIcon {
  color: coral;
}

.copyIcon {
  color: coral;
}

.deactivatedVoiceIcon {
  color: coral;
}

.activatedVoiceIcon {
  color: dodgerblue;
}

.topContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.options {
  position: absolute;
  border: 1px solid coral;
  top: 20px;
  right: 50px;
  z-index: 100;
  background: white;
  border-radius: 6px;
  padding: 8px;
}

.vocabulariesList {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid coral;
  top: 20px;
  right: 40px;
  z-index: 100;
  background: white;
  border-radius: 6px;
  padding: 10px 15px 0px 15px;
  cursor: default;
}

.vocabulariesListItem {
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: coral;
  }
}

.translationRoot {
  width: 100%;
}

.translation {
  height: auto;
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dictionary {
  border-top: 1px solid rgb(211, 211, 211);
  padding-top: 10px;
  margin-right: 20px;
}

// @media (max-width: 600px) {
//   .root {
//     width: calc(100vw - 100px);
//     height: 90px;
//     align-items: flex-start;
//   }

//   // .cart {
//   //   height: 100%;
//   //   width: calc(100vw - 170px);
//   //   align-items: flex-start;
//   //   padding: 10px 0px;
//   //   white-space: wrap;
//   //   overflow-y: auto;
//   // }

//   .index {
//     margin-top: 10px;
//     margin-right: 5px;
//   }
// }
