.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toDo {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(195, 195, 195);
  border-radius: 6px;
  padding: 15px;
  align-items: center;
  cursor: pointer;
}

.name {
  font-size: 14px;
}

.dateContainer {
  margin-left: 20px;
  font-size: 12px;
  width: 87px;
}

.priorityContainer {
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 100px;
}

.priority {
  background-color: lime;
  color: green;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 14px;
}
