.root{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(209, 209, 209, 0.371);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px 40px 50px;
  background-color: white;
}

.title{
  font-size: 25px;
  font-weight: 500;
  color: rgb(255, 116, 73);
  margin-bottom: 10px;
}

.buttonContainer{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.cancelButton{
  margin-right: 60px;
}