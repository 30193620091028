$headerBackground: rgb(248, 248, 248);

.root{
  height: 60px;
  width: 100%;
  background-color: $headerBackground;
  display: flex;
  flex-direction: row;
}

.IconContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  background-color: rgb(1, 10, 46);
}

.menuIconContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
}

.menuIcon{
  cursor: pointer;
}

.burger{
  position: absolute;
  top: -13px;
  left: -18px;
}

.header{
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
}

.title{
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
}