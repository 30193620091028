$itemColor: #f2f2f29a;

.root {
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  height: 100%;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.filters {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.firstGroupFilters {
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 10px;
  }
}

.secondGroupFilters {
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 10px;
  }
}

.addObjective {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
}

.main {
  width: 100%;
  height: calc(100vh - 40px);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 150px;
}

.icon {
  cursor: pointer;
}

.objective {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-self: center;
  background-color: $itemColor;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid $itemColor;
  cursor: pointer;
  text-decoration: none;
}

.objective:hover {
  border: 1px solid #36e244;
}

.activeObjective {
  background-color: rgb(250, 250, 218) !important;
}

.pausedObjective {
  background-color: rgb(244, 244, 244) !important;
}

.finishedObjective {
  background-color: rgb(234, 242, 234) !important;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-right: auto;
  color: #19161670;
  width: 329px;
  overflow: hidden;
  white-space: nowrap;
}

.left {
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controls {
  width: 280px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  > * {
    margin-right: 20px;
  }
}
.dropdown {
  width: 120px;
  display: flex;
}

.select {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  &:first-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    background-color: white !important;
    color: rgb(118, 118, 118) !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    &::after {
      margin-top: 10px;
      margin-left: auto !important;
    }
  }
}

.columnsContainer {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.column {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.columnTitle {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
  background-color: #fcfdfd;
  gap: 15px;
  height: 100%;
  border-radius: 6px;
}

.smallScreenColumnsContainer {
  width: 100%;
  height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.smallScreenTabs {
  width: 100%;
}

.smallScreenColumn {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .main {
    align-items: start;
  }

  .width {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .root {
    padding: 20px 5px 40px 5px;
  }
}

@media (max-width: 570px) {
  .filters {
    width: 260px;
    flex-wrap: wrap;
  }

  .firstGroupFilters {
    margin-bottom: 20px;
  }

  .secondGroupFilters {
    > * {
      margin-right: 25px;
    }
  }
}
