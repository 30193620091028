.root {
  width: 100%;
  height: 100%;
}

.menuButton {
  background-color: rgb(236, 236, 236);
  position: absolute;
  top: 75px;
  left: 75px;
  z-index: 100;
  padding: 7px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.menuDropdown {
  width: auto;
  border: 1px solid rgb(236, 236, 236);
  position: absolute;
  top: 115px;
  left: 75px;
  z-index: 1000;
  border-radius: 5px;
  background-color: white;
}

.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px;
  gap: 5px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgb(229, 229, 229);
  }
}
