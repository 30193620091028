.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(225, 225, 225, 0.508);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 500px;
}

.confirm {
  width: 100%;
  overflow-wrap: break-word;
  margin-bottom: 50px;
  font-weight: 700;
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.confirmBtn {
  margin-left: 80px;
}
