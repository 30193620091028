.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.picker {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  font-family: 'GitlabMono';
  > div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 10px;
    color: rgb(118, 118, 118);
  }
}

.line {
  width: 100%;
  margin-bottom: 15px;
}

.label {
  font-weight: 700;
  font-family: 'GitlabMono';
}

.textInput::placeholder {
  font-family: 'GitlabMono';
}

.textInput {
  font-family: 'GitlabMono';
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
}

.createBtn {
  margin-left: auto;
}

@media (max-width: 900px) {
  .table {
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 500px) {
  .btnContainer {
    padding: 20px 0px;
  }
}
