@mixin flexRow {
  display: flex;
  flex-direction: row;
}

.root{
  width: 100%;
  padding: 30px;
}

.objectiveInfo{

}

.objectiveInfoTop{
  @include flexRow;
}


.objectiveName{
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  height: 54px;
}

.dropdowns {
  @include flexRow;
  margin-left: auto;
}

.dropdown{
  @include flexRow;
  margin-left: 20px;
  width: 120px;
}

.select{
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  &:first-child{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    background-color: white !important;
    color: rgb(118, 118, 118)!important;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    &::after{
      margin-top: 10px;
      margin-left: auto !important;
    }
  }
}

.descriptionContainer{
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: rgb(235, 235, 235);
  min-height: 150px;
  overflow-y: auto;
  cursor: pointer;
}

.description{
  
}

.noDate{
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  color: #a7acb1;
  cursor: pointer;
  margin-left: 10px;
}

.createdAt{
  margin-right: 5px;
}

.deadLine{
  margin-right: 5px;
}

.deadlineContainer{
  display: flex;
  flex-direction: row;
}

.deadlineDate{
  margin-left: 10px;
  cursor: pointer;
}

.finishedDate{
  display: flex;
  flex-direction: row;
}

.finishedAt{
  margin-right: 5px;
}

.descriptionTitle{
  margin-bottom: 5px;
}

.separator{
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: rgb(219, 224, 232);
}

.stepsTop{
  width: 100%;
  @include flexRow;
}

.stepsTitle{
  width: 100%;
  font-size: 25px;
  font-weight: 500;
}

.stepsTopLeft{
  width: calc(100% - 96px);
}

.steps{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.step{
  width: 100%;
  min-height: 200px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 25px;
}

.activeStep{
  background-color: rgb(217, 233, 217);
}

.pausedStep{
  background-color:  rgb(242, 242, 196);
}

.finishedStep{
  background-color: rgb(250, 215, 215);
}

.stepTop{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.stepName{
  font-size: 20px;
  font-weight: 400;
  margin-right: auto;
}

.editButtonContainer{
  height: 40px;
  width: 40px;
  margin-left: 20px;
}

.deleteButtonContainer{
  height: 40px;
  width: 40px;
  margin-left: 10px;
}

.date{
  display: flex;
  align-items: center;
  color: #5c5c5c;
}

.deadline{
  display: flex;
  align-items: center;
  color: #5c5c5c;
}

.picker{
  margin-left: 10px;
}
