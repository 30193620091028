.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  &:hover .sideBar {
    display: flex;
  }
}

.children {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideBar {
  width: 160px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background-color: rgb(253, 251, 249);
  padding: 0px 0px;
  display: none;
  position: absolute;
  left: 60px;
  top: 60px;
  z-index: 1000;
  overflow: hidden;
}

.main {
  width: calc(100% - 160px);
  height: 100%;
}

.navLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  text-decoration: none;
  color: black;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: lightcoral;
  }
}

.activeNavLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  text-decoration: none;
  color: lightcoral;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: lightcoral;
  }
}

.icon {
  font-size: 18px;
}

.name {
  margin-top: 4px;
  margin-left: 8px;
}

@media (max-width: 1024px) {
  .sideBar {
    height: 100vh;
    top: 0px;
  }
}
