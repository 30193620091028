@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  width: 60px;
  height: 100%;
  background-color: #36e244;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: start;
  > * {
    height: 60px;
    width: 60px;
    @include center;
  }
}

.navLink {
  cursor: pointer;
  height: 60px;
  width: 60px;
  @include center;
}

.navLink:hover {
  background-color: #2dcd3b;
}

.activeNavLink {
  cursor: pointer;
  height: 60px;
  width: 60px;
  @include center;
  background-color: #2dcd3b;
  border-top: 2px solid rgb(253, 251, 249);
  border-bottom: 2px solid rgb(253, 251, 249);
}
