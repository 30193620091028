.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  // height: calc(100% - 100px);
  padding: 30px;
  overflow-y: auto;
}

.picker {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  > div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 10px;
    color: rgb(118, 118, 118);
  }
}

.selectContainer,
.textareaContainer {
  margin-top: 15px;
}

.select {
  display: flex;
  width: 100%;
  align-items: center;
  &:first-child {
    background-color: white;
    color: rgb(118, 118, 118);
    border: 1px solid #ced4da;
    border-radius: 4px;
    &:focus {
      background-color: white;
      color: rgb(118, 118, 118);
    }
    &::after {
      margin-left: auto !important;
    }
  }
}

.textarea {
  height: 150px;
  resize: none;
}

.error {
  width: 100%;
  padding: 10px;
  color: rgb(199, 17, 17);
  background-color: rgb(255, 134, 134);
}
