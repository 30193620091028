.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 120px;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  height: 60px;
  padding-left: 20px;
  background-color: white;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
}

.noteName {
  font-size: 16px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  text-transform: capitalize;
  color: rgb(255, 116, 73);
}

.noteDate {
  font-size: 12px;
}

.btnContainer {
  width: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  position: relative;
  margin-right: 10px;
}

.notePriority {
  font-size: 14px;
  font-weight: 600;
  color: rgb(88, 88, 88);
}

.btnTable {
  height: 100px;
  position: absolute;
  top: 40px;
  right: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  justify-content: space-between;
  background-color: white;
  > * {
    cursor: pointer;
  }
}

.content {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  font-size: 12px;
  white-space: pre-wrap;
}

.footer {
  background-color: rgb(241, 231, 225);
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
}

.dateContainer {
  margin-left: auto;
}

@media (max-width: 380px) {
  .dateContainer {
    display: flex;
    flex-direction: column;
  }
}
