.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

main{
  width: 100%;
}

.bottom{
  display: flex;
  flex-direction: row;
  height: 100%;
}
