.root {
  width: 100%;
  border: 1px solid rgb(212, 212, 212);
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: lightgray;
  display: inline-block;
  padding: 5px 35px 5px 15px;
  border-radius: 20px;
  position: relative;
  font-family: 'GitlabMono';
}

.text {
}

.close {
  height: 16px;
  width: 16px;
  background-color: black;
  color: white;
  margin-left: 5px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 12px;
}

.input {
  flex-grow: 1;
  padding: 5px 10px;
  border: none;
  outline: none;
  font-family: 'GitlabMono';
}

.input::placeholder {
  font-family: 'GitlabMono';
}
