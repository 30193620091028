.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 700;
  font-family: 'GitlabMono';
}

.textInput {
  font-family: 'GitlabMono';
}

.textInput::placeholder {
  font-family: 'GitlabMono';
}

.line {
  width: 100%;
  margin-bottom: 15px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  margin-top: auto;
}

.createBtn {
  margin-left: auto;
}

@media (max-width: 900px) {
  .table {
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 500px) {
  .btnContainer {
    padding: 20px 0px;
  }
}
