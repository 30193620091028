.root {
  width: 100%;
  height: 100%;
  font-family: 'GitlabMono';
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.priorityContainer {
  display: flex;
  flex-direction: row;
}

.priority {
  margin-right: 10px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
}

.date {
  margin-right: 10px;
}
