@mixin mainPadding {
  padding: 20px;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  @include mainPadding;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.block {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: calc((100vh - 100px));
  border: 1px rgb(207, 207, 207) solid;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  @include mainPadding;
}

@media (max-width: 1100px) {
  .row {
    flex-direction: column;
  }

  .block {
    width: 100%;
    height: calc((100vh - 120px) / 2);
  }
}
