.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'GitlabMono';
}

.inlineContainer {
  display: flex;
  flex-direction: row;
}
