.root {
  padding: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(235, 235, 235);
  }
}

.disabled {
  opacity: 0.5;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .root {
    &:hover {
      background-color: white;
    }
  }
}
