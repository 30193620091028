@font-face {
  font-family: Arvo;
  src: url('./fonts/Arvo-Regular.ttf');
}

@font-face {
  font-family: GitlabMono;
  src: url('./fonts/GitLabMono.ttf');
}

.App {
  font-family: 'GitlabMono' !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #36e244;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #30c23c;
}
/* fixed excalidraw main menu open bug */
.excalidraw .dropdown-menu {
  display: unset !important;
  padding: unset !important;
}
