.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 700;
  font-family: 'GitlabMono';
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
}

.createBtn {
  margin-left: auto;
}

.error {
  width: 100%;
  padding: 10px;
  color: rgb(199, 17, 17);
  background-color: rgb(255, 134, 134);
}
