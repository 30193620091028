.objective {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background-color: transparent;
  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid white;
  cursor: pointer;
  text-decoration: none;
  background-color: white;
}

.objective:hover {
  border: 1px solid #36e244;
}

.activeObjective {
  background-color: rgb(217, 233, 217) !important;
}

.pausedObjective {
  background-color: rgb(242, 242, 196) !important;
}

.finishedObjective {
  background-color: rgb(250, 215, 215) !important;
}

.top {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: 500;
  color: #19161670;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.dropdown {
  width: 120px;
  display: flex;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.select {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  &:first-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    background-color: white !important;
    color: rgb(118, 118, 118) !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    &::after {
      margin-top: 10px;
      margin-left: auto !important;
    }
  }
}
