.root {
}

.drawerES {
  height: 132px !important;
}

.drawerVS {
  height: 180px !important;
}

.drawerS {
  height: 400px !important;
}

.drawerSM {
  height: 500px !important;
}

.drawerM {
  height: 600px !important;
}

.drawerML {
  height: 700px !important;
}

.drawerL {
  height: 800px !important;
}

.drawerX {
  height: 1000px !important;
}

.drawerFull {
  height: 100% !important;
}

.drawerHeader {
  background-color: #36e244;
}

.drawerTitle {
  color: white;
  font-family: 'GitlabMono';
}

.drawerBody {
  overflow: hidden !important;
}
