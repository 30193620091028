.root {
  width: 100%;
  height: calc(100% - 60px);
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.addEventButtonContainer {
  margin-left: auto;
  margin-bottom: 20px;
}

.addButton {
  height: 40px;
}

.calendarContainer {
  width: 100%;
  height: calc(100vh - 540px);
}

.calendar {
  width: 100%;
  height: calc(100vh - 240px);
}

.event {
  height: 100%;
  background-color: burlywood;
  border-color: red;
}

@media (max-width: 1000px) {
  .root {
    padding: 20px 5px 60px 5px;
  }
}
