.root {
}

.select {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: 'GitlabMono';
  &:first-child {
    background-color: white;
    color: rgb(118, 118, 118);
    border: 1px solid #ced4da;
    border-radius: 4px;
    display: flex;
    align-items: center;
    &:focus {
      background-color: white;
      color: rgb(118, 118, 118);
    }
    &::after {
      margin-left: auto !important;
    }
  }
}

.menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown {
  width: 100%;
}

.label {
  font-weight: 700;
  font-family: 'GitlabMono';
}

.item {
  font-family: 'GitlabMono';
}
