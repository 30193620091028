.root{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table{
  min-width: 300px;
  width: 400px;
  padding: 30px 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 5px #ccc;
}

.btnContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.loginFailText{
  color: red;
}

.emptyText{
  width: 100%;
  height: 24px;
}

@media (max-width: 500px) {
  .table{
    width: 250px;
  }
}