.root {
  display: relative;
  width: 100%;
  height: 100vh;
}

.content {
  width: 100%;
  height: calc(100% - 135px);
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  padding: 0px 20px 20px 20px;
}

.linkContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.linkTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;
}

.addLink {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-right: 20px;
}

.folder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.back {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.folderName {
  margin-left: 5px;
}

.link {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: none;
}

.btnContainer {
  width: 90px;
  display: flex;
  flex-direction: row;
  margin-top: auto;
  gap: 10px;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(220, 220, 220, 0.487);
  padding-top: 100px;
}
