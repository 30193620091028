.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(130, 130, 130, 0.261);
}

.table {
  width: 600px;
  overflow-y: auto;
  padding: 30px;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}

.form {
}

.title {
  width: 100%;
  color: rgb(255, 116, 73);
  text-align: center;
  margin-bottom: 40px;
}

.picker {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  > div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 10px;
    color: rgb(118, 118, 118);
  }
}

.line {
  width: 100%;
  margin-bottom: 15px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
}

.createBtn {
  margin-left: auto;
}

@media (max-width: 800px) {
  // .table{
  //   width: 80vw;
  // }
}

@media (max-width: 500px) {
  .btnContainer {
    padding: 20px 0px;
    margin-top: auto;
  }

  .title {
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .table {
    height: 100vh;
  }
}
