.root {
  width: 100%;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  position: relative;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
}

.topContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  height: 40px;
}

.name {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 700;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  padding: 3px 10px;
  border-radius: 10px;
  background-color: moccasin;
}

.createdAt {
  margin-top: 10px;
  font-size: 13px;
}
