@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rootClose {
  position: fixed;
  top: 60px;
  width: 100vw;
  height: 0px;
  background-color: rgba(219, 217, 217, 0.353);
  transition: height 0.5s ease-in-out;
  z-index: 100;
}

.rootOpen {
  position: fixed;
  top: 60px;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: rgba(219, 217, 217, 0.353);
  transition: height 0.5s ease-in-out;
  z-index: 100;
}

.drawerClose {
  width: 60px;
  height: 0px;
  background-color: #36e244;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: start;
  transition: height 0.5s ease-in-out;
  > * {
    height: 60px;
    width: 60px;
    @include center;
  }
}

.drawerOpen {
  width: 60px;
  height: calc(100vh - 60px);
  background-color: #36e244;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: start;
  transition: height 0.5s ease-in-out;
  > * {
    height: 60px;
    width: 60px;
    @include center;
  }
}

.firstLink {
}

.activeNavLink {
  cursor: pointer;
  height: 60px;
  width: 60px;
  @include center;
  background-color: #2dcd3b;
  border-top: 2px solid rgb(253, 251, 249);
  border-bottom: 2px solid rgb(253, 251, 249);
}
