.root {
  width: 100%;
  padding-top: 10px;
  height: 100%;
}

.recipeTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0px 20px 0px 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.icon {
  color: #0d6efd;
}

.recipeContainer {
  display: flex;
  flex-direction: column;
}

.memories {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px;
}

.recipe {
  margin-bottom: 20px;
}
