@mixin mainPadding {
  padding: 0px 20px 0px 20px;
}

.root {
  width: 100%;
  padding-top: 10px;
  height: 100%;
}

.locationTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  @include mainPadding;
}

.addLocation {
  margin-left: auto;
}

.locationContainer {
  display: flex;
  flex-direction: column;
}

.locations {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px;
}

.location {
  margin-bottom: 20px;
}
