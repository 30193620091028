.root {
  width: 100%;
  height: 100%;
  font-family: 'GitlabMono';
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.tagsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.tags {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.tag {
  padding: 3px 10px;
  border-radius: 10px;
  background-color: moccasin;
  min-width: auto;
}
