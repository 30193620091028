.root {
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  height: 100%;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.addWhiteboard {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
}

.icon {
  cursor: pointer;
}

.main {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: start;
  justify-content: start;
}

.whiteboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 10px;
}

.name {
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
