.root{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(130, 130, 130, 0.261);
}

.table{
  width: 600px;
  padding: 30px;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
}

.title {
  width: 100%;
  color: rgb(255, 116, 73);
  text-align: center;
}

.textareaContainer{
  margin-top: 15px;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
}

.createBtn{
  margin-left: auto;
}

.dataPickerContainer{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}