.dates {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 3px;
  padding: 10px;
}

.date {
  background-color: rgb(140, 255, 138);
  border: 1px solid rgb(65, 200, 63);
  display: inline-block;
  padding: 5px 35px 5px 15px;
  border-radius: 5px;
  position: relative;
  font-family: 'GitlabMono';
}

.picker {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  font-family: 'GitlabMono';
  > div {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 10px;
    color: rgb(118, 118, 118);
  }
}

.close {
  height: 16px;
  width: 16px;
  background-color: black;
  color: white;
  margin-left: 5px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 12px;
}
