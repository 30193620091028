.root {
  padding: 30px 20px;
}

.header {
  display: flex;
  flex-direction: row;
}
.icon {
  color: #0d6efd;
}

.vocabulariesDropdown {
  min-width: 200px;
  margin-right: auto;
}

.vocabularies {
  min-width: 270px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addVocabularyButton {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  * > {
    margin-right: 0px;
  }
}

.addIcon {
  color: green;
}

.updateIcon {
  color: coral;
}

.deleteIcon {
  color: red;
}

.changeWordsIcon {
  color: indianred;
}

.words {
  height: calc(100vh - 160px);
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgb(98, 98, 98);
  overflow-y: auto;
  overflow-x: hidden;
}

.word {
  margin-bottom: 15px;
}

@media (max-width: 1000px) {
  .root {
    padding: 20px 5px 40px 5px;
  }
}

@media (max-width: 500px) {
  .vocabularies {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonContainer {
    margin-top: 10px;
    padding: 0px;
  }

  .words {
    height: calc(100vh - 200px);
    margin-top: 10px;
  }
}
