.root {
  width: 100%;
  height: 100%;
  font-family: 'GitlabMono';
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.priorityContainer {
  display: flex;
  flex-direction: row;
}

.priority {
  margin-right: 10px;
}

.statusContainer {
  display: flex;
  flex-direction: row;
}

.status {
  margin-right: 10px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
}

.date {
  margin-right: 10px;
}

.whiteboardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whiteboards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whiteboardTitle {
  border-bottom: 1px solid rgb(164, 164, 164);
}

.whiteboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 10px;
}

.name {
  display: flex;
  align-items: centre;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.whiteboardButtonContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: auto;
  gap: 15px;
  margin-top: auto;
}

.todosContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.todos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toDoTitle {
  border-bottom: 1px solid rgb(164, 164, 164);
}

.todo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 10px;
}

.todoActiveStatus {
  margin-left: 15px;
  background-color: rgb(250, 250, 218);
  color: rgb(139, 139, 25);
  border: 1px solid rgb(139, 139, 25);
  padding: 3px 10px;
  border-radius: 5px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoFinishedStatus {
  margin-left: 15px;
  background-color: rgb(234, 242, 234);
  color: rgb(40, 170, 40);
  border: 1px solid rgb(40, 170, 40);
  padding: 3px 10px;
  border-radius: 5px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoPausedStatus {
  margin-left: 15px;
  background-color: rgb(244, 244, 244);
  color: rgb(134, 134, 134);
  border: 1px solid rgb(134, 134, 134);
  padding: 3px 10px;
  border-radius: 5px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoHighPriority {
  margin-left: 15px;
  background-color: rgb(234, 242, 234);
  color: rgb(40, 170, 40);
  border: 1px solid rgb(40, 170, 40);
  padding: 3px 10px;
  border-radius: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoMediumPriority {
  margin-left: 15px;
  background-color: rgb(250, 250, 218);
  color: rgb(139, 139, 25);
  border: 1px solid rgb(139, 139, 25);
  padding: 3px 10px;
  border-radius: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoLowPriority {
  margin-left: 15px;
  background-color: rgb(244, 244, 244);
  color: rgb(134, 134, 134);
  border: 1px solid rgb(134, 134, 134);
  padding: 3px 10px;
  border-radius: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todoButtonContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.rightPart {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
