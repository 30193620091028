.root {
}

.drawerS {
  width: 400px !important;
}

.drawerSM {
  width: 500px !important;
}

.drawerM {
  width: 600px !important;
}

.drawerML {
  width: 700px !important;
}

.drawerL {
  width: 800px !important;
}

.drawerX {
  width: 1000px !important;
}

.drawerFull {
  width: 100% !important;
}

.drawerHeader {
  background-color: #36e244;
}

.drawerTitle {
  color: white;
  font-family: 'GitlabMono';
}
