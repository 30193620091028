@mixin flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.root{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
}

.children{
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  padding-bottom: 60px;
  margin-bottom: 140px;
}

.footer{
  position: fixed;
  bottom: 0;
  width: calc(100% - 60px);
  min-height: 80px;
  padding: 0 30px;
  border-top: 4px solid rgb(240, 240, 240);
  background-color: white;
  @include flexRow;
  margin-top: auto;
}

.right{
  @include flexRow;
  margin-left: auto;
  >*{
    margin-left: 20px;
  }
}

.button{
  width: 100px;
}