.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eeecec;
  padding: 1px 7px 1px 7px;
  border-radius: 5px;
  font-weight: bold;
}

.icon {
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
