.root {
  width: 100%;
  height: 100%;
}

.notesTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.addToDo {
  margin-left: auto;
}

.icon {
  color: #0d6efd;
}

.toDoes {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  // margin: 20px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px 0px 20px;
}

.columnsContainer {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.column {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.columnTitle {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
  background-color: #fcfdfd;
  gap: 15px;
  height: 100%;
  border-radius: 6px;
}

.smallScreenColumnsContainer {
  width: 100%;
  height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.smallScreenTabs {
  width: 100%;
}

.smallScreenColumn {
  width: 100%;
  height: 100%;
}

.activeToDo {
  background-color: rgb(234, 242, 234) !important;
}

.pausedToDo {
  background-color: rgb(253, 253, 217) !important;
}

.finishedToDo {
  background-color: rgb(254, 231, 231) !important;
}

@media (max-width: 1024px) {
}
